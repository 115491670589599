@import "tailwindcss";

@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";


.color-hoist-orange {
  color: #FF693C
}

.bg-color-hoist-orange {
  background-color: #FF693C;
}

.color-hoist-green {
  color: #AFCDC3;

}

.bg-color-hoist-green {
  background-color: #AFCDC3;

}

.text-huge {
  font-size: calc(32px + 3vw);
}

.post {
  li {
    list-style-type: circle;
    margin-left: 24px;
    padding-top: 12px;
  }
  h1, h2, h3 {
    margin-top: 24px;
    margin-bottom: 12px;
  }
  h1 {
    font-size: 2.5em;
    font-weight: bold;
  }
  h2 {
    font-size: 2em;
    font-weight: bold;
  }
  h3 {
    font-size: 1.5em;
    font-weight: bold;
  }

  p {
    padding-bottom: 12px;
  }
}